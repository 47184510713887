import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

@Injectable({ providedIn: "root" })
export class ModuleAcceessService {
    modules = [


        {
            name: "VideoListing",
            menuName: "Matches",
            class: "fa fa-futbol-o",
            parentRoute: "/main/video-listing",
            menu: false,
            submenu: []
        },
        {
            name: "Player",
            menuName: "Players",
            class: "fa fa-user",
            parentRoute: "/main/player-listing",
            menu: false,
            submenu: []
        },
        {
            name: "Team",
            menuName: "Teams",
            class: "fa fa-users",
            parentRoute: "/main/team-listing",
            menu: false,
            submenu: []
        },
        {
            name: "Action",
            menuName: "Actions",
            class: "fa fa-video-camera",
            parentRoute: "/main/action",
            menu: false,
            submenu: []
        },

        {
            name: "FilterPlayer",
            menuName: "Filtered Players",
            class: "fa fa-filter",
            parentRoute: "/main/filteredPlayers",
            menu: false,
            submenu: []
        },
        {
            name: "Stats",
            menuName: "Stats",
            class: "fa fa-line-chart",
            parentRoute: "/main/stats",
            menu: false,
            submenu: []
        },
        {
            name: "MetaDataUploader",
            menuName: "MetaData-Uploader",
            class: "fa fa-upload",
            parentRoute: "/main/metaData/upload",
            menu: false,
            submenu: []
        },
        // {
        //     name: "parserSetting",
        //     menuName: "Parser Setting",
        //     class: "fa fa-cog",
        //     parentRoute: "/main/parser",
        //     menu: false,
        //     submenu: []
        // },
        // {
        //     name: "Cache",
        //     menuName: "Settings",
        //     class: "fa fa-cog",
        //     parentRoute: "/main/settings",
        //     menu: false,
        //     submenu: []
        // },

        {
            name: "setting",
            menuName: "Settings",
            class: "fa fa-cog",
            menu: false,
            submenu: [
                {
                    name: "Cache",
                    menuName: "Cache",
                    class: "fa fa-database",
                    route: "/main/settings",
                },
                {
                    name: "parserSetting",
                    menuName: "Parser",
                    class: "fa fa-file-text-o",
                    route: "/main/parser"
                },
                // {
                //     name: "system",
                //     menuName: "System",
                //     class: "fa fa-laptop",
                //     route: "/main/system"
                // }
            ]
        },


        {
            name: "Fixtures",
            menuName: "Fixtures",
            class: "fa fa-sitemap",
            parentRoute: "/main/fixtures",
            menu: false,
            submenu: []
        },
        {
            name: "AwsVideos",
            menuName: "Aws-Videos",
            class: "fa fa-file-video-o",
            parentRoute: "/main/videolist",
            menu: false,
            submenu: []
        },
        {
            name: "AwsJobs",
            menuName: "Aws-Jobs",
            class: "fa fa-amazon",
            parentRoute: "/main/jobList",
            menu: false,
            submenu: []
        },
        {
            name: "SyncingTool",
            menuName: "Syncing Tool",
            class: "fa fa-code-fork",
            parentRoute: "/main/syncing-tool",
            menu: false,
            submenu: []
        },
        {
            name: "MetaDataGenerator",
            menuName: "MetaData-Generator",
            class: "fa fa-file-code-o",
            parentRoute: "/main/metaData/generate",
            menu: false,
            submenu: []
        },
        {
            name: "XmlChecker",
            menuName: "XML-Checker",
            class: "fa fa-search-plus",
            parentRoute: "/main/xml-checker",
            menu: false,
            submenu: []
        },

        {
            name: "Configuration",
            menuName: "Configurations",
            class: "fa fa-cogs",
            parentRoute: "/main/configurations",
            menu: false,
            submenu: []
        },
        {
            name: "API",
            menuName: "API",
            class: "fa fa-pie-chart",
            parentRoute: "/main/analytics",
            menu: false,
            submenu: []
        },
        {
            name: "Analytics",
            menuName: "Analytics",
            class: "fa fa-bar-chart",
            menu: false,
            submenu: [
                {
                    name: "Summary",
                    menuName: "Summary",
                    class: "fa fa-tachometer",
                    route: "/main/g-analytics/dashboard",
                },
                {
                    name: "Audience",
                    menuName: "Audience",
                    class: "fa fa-group",
                    route: "/main/g-analytics/audience",
                },
                // {
                //     name: "Player",
                //     menuName: "Player",
                //     class: "fa fa-user",
                //     route: "/main/g-analytics/players",
                // },
                // {
                //     name: "Game",
                //     menuName: "Game",
                //     class: "fa fa-futbol-o",
                //     route: "/main/g-analytics/games",
                // },
            ]
        },
        {
            name: "ViberReport",
            menuName: "Viber Report",
            class: "fa fa-pie-chart",
            parentRoute: "/main/g-analytics/viber-report",
            menu: false,
            submenu: []
        },
        {
            name: "Embed",
            menuName: "Vidgets",
            class: "fa fa-link",
            parentRoute: "/main/embed",
            menu: false,
            submenu: []
        },
        {
            name: "whitelistdomain",
            menuName: "Whitelist Domain",
            class: "fa fa-external-link",
            parentRoute: "/main/whitelistDomain",
            menu: false,
            submenu: []
        },
        {
            name: "ClubListing",
            menuName: "Clubs",
            class: "fa fa-cc-diners-club",
            parentRoute: "/main/api/club",
            menu: false,
            submenu: []
        },
        {
            name: "User",
            menuName: "Users",
            class: "fa fa-user-secret",
            parentRoute: "/main/users",
            menu: false,
            submenu: []
        },

        {
            name: "Capabilities",
            menuName: "Capabilities",
            class: "fa fa-braille",
            parentRoute: "/main/capabilities",
            menu: false,
            submenu: []
        },

        {
            name: "Roles",
            menuName: "Roles",
            class: "fa fa-address-book",
            parentRoute: "/main/roles",
            menu: false,
            submenu: []
        },

        {
            name: "Faq",
            menuName: "FAQ",
            class: "fa fa-question",
            parentRoute: "/main/faq",
            menu: false,
            submenu: []
        },
        {
            name: "Page",
            menuName: "Pages",
            class: "fa fa-file",
            parentRoute: "/main/pages",
            menu: false,
            submenu: []
        },
        {
            name: "vibermessenger",
            menuName: "Viber Messenger",
            class: "fa fa-bullhorn",
            parentRoute: "/main/viber-mesenger",
            menu: false,
            submenu: []
        },
        {
            name: "StaticPages",
            menuName: "Static Pages",
            class: "fa fa-file",
            parentRoute: "/main/pages",
            menu: false,
            submenu: []
        },
    ];
    constructor(private http: HttpClient) { }

    getModuleInfo() {
        return this.modules;
    }
}
